export const APPROVED_MEMBERS = [
  9498, // Tom Capote
  9559, // Andrew Soo hoo
  13047, // Faten Haj Ibrahim
  12914, // Fred Pannebaker
  2974, // David Brown
  15766, // Michael Staubly
  6663, // Henning Visser
  9710, // Niranjan Yedlapati
  3969, // Jose Cueva
  10290, // Lindsay Hamilton
  9505, // Joshua Boucher
  18275, // Melani Benedict
  16949, // Tanya Bakshi
  16616, // Chad Chotikasatien
  18057, // Miguel De La Torre
  16611, // Alan Li
  14964, // Lou DeSimone
  16593, // Justin Nguyễn
  16784, // Seth Peelle
  16727, // David Reid
  17830, // Mary Romanov
  15987, // Diane Soloveichik,
  99999 // SUPER USER FOR CYPRESS TESTINg
];

export const TEAM_BACKLOG_QUERY_FILTERS = {
  statusTypes: [],
  subStatusTypes: [],
  titleSearch: '',
  requestIds: [],
  classificationIds: [],
  // NOTE: Hide in Sprint 61
  // priorityScoreLow: '',
  // priorityScoreHigh: '',
  RIDateFrom: null,
  RIDateTo: null,
  PIDateFrom: null,
  PIDateTo: null,
  noRIDate: false,
  noPIDate: false,
  regions: [],
  referenceIds: [],
  reviewerIds: false,
  noReviewerIds: false,
  noPM: null,
  leadIds: false,
  noLeadIds: false,
  requiresValidation: false,
  escalatedProject: false,
  scheduleRisk: false,
  businessImpacts: [],
  escalationRequested: null,
  escalationApproved: null,
  requestCategories: [],
  documentTypeId: [],
  leadTeams: []
};

export const IN_PROCESS_QUERY_FILTERS = {
  statusTypes: [],
  subStatusTypes: [],
  titleSearch: '',
  requestIds: [],
  classificationIds: [],
  // NOTE: Hide in Sprint 61
  // priorityScoreLow: '',
  // priorityScoreHigh: '',
  RIDateFrom: null,
  RIDateTo: null,
  PIDateFrom: null,
  PIDateTo: null,
  noRIDate: false,
  noPIDate: false,
  regions: [],
  referenceIds: [],
  reviewerIds: false,
  noReviewerIds: false,
  noPM: null,
  leadIds: false,
  noLeadIds: false,
  requiresValidation: false,
  escalatedProject: false,
  scheduleRisk: false,
  businessImpacts: [],
  escalationRequested: null,
  escalationApproved: null,
  requestCategories: [],
  documentTypeId: [],
  leadTeams: []
};

export const TEAM_BACKLOG_QUERY_SORT = {
  ['bsort.col']: 'priorityScore',
  ['bsort.dir']: 2
};

export const IN_PROCESS_QUERY_SORT = {
  ['isort.col']: 'priorityScore',
  ['isort.dir']: 2
};

export const TEAM_PLANNER_ESCALATION_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];
